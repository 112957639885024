import { TransactionStatus, transactionStatusColors } from "../../../utils/transaction";

export function StatusPill({ status }: { status: TransactionStatus }) {
  return (
    <p
      className={`rounded-sm max-w-fit px-2 text-xs ${transactionStatusColors[status]}`}
    >
      {status}
    </p>
  );
}
