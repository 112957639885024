import { jsPDF } from "jspdf";
import { Transaction } from "../../../services/queries/transactions/types";
import { formatDateTime } from "../../../utils/transaction";
import { PaymentMethod, WithdrawalType } from "../../../constants";

function formatAmount(amount: number | undefined): string {
  if (amount === undefined || amount === null) return "0.00";

  return amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function safeText(text: string | undefined | null): string {
  return text || "";
}

export function generateReceipt(transaction: Transaction, bankName: string) {
  const doc = new jsPDF({
    unit: "mm",
    format: "a4",
  });

  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;
  const contentWidth = pageWidth - margin * 2;

  const logoHeight = 10;
  const logoAspectRatio = 6.8;
  const logoWidth = logoHeight * logoAspectRatio;
  const logoX = (pageWidth - logoWidth) / 2;

  const isBank = transaction.beneficiary.paymentMethod === PaymentMethod.BANK;

  function wrapText(doc: jsPDF, text: string, width: number): string {
    return doc.splitTextToSize(text, width);
  }

  function addText(doc: jsPDF, text: string, x: number, y: number, width: number) {
    const wrappedText = wrapText(doc, text, width);
    doc.text(wrappedText, x, y);
  }

  const maxWidth = 80;
  const isCurrencyExchange = transaction.withdrawalType === WithdrawalType.INTERNATIONAL;

  const amountText = isCurrencyExchange
    ? `${formatAmount(transaction.quoteAmount)} ${safeText(transaction.quoteCurrency)}`
    : `${formatAmount(transaction.amount)} ${safeText(transaction.currency)}`;

  let currentVerticalPosition = 100; // Clearer name

  doc.addImage("/full-logo.png", "PNG", logoX, 15, logoWidth, logoHeight);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Transaction Receipt", margin, 35);

  doc.setFontSize(10);
  doc.text(`Created Date: ${formatDateTime(transaction.created)}`, margin, 45);
  doc.text(`Completed Date: ${formatDateTime(transaction.created)}`, margin, 52);


  /*** Transfer Overview ***/
  doc.setFontSize(11);
  doc.setFont("helvetica", "bold");
  doc.text("Transfer Overview", margin, 65);

  doc.setFillColor(247, 250, 252);
  doc.roundedRect(margin, 70, contentWidth, 25, 2, 2, "F");

  doc.setFontSize(9);
  doc.setTextColor(128, 128, 128);
  doc.text("You Sent", margin + 5, 78);
  doc.text("Recipient Got", margin + contentWidth / 2, 78);

  doc.setFontSize(11);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text(`${formatAmount(transaction.amount)} ${safeText(transaction.currency)}`, margin + 5, 88);
  doc.text(amountText, margin + contentWidth / 2, 88);


  /*** Exchange Rate (Separate Section) ***/
  if (isCurrencyExchange) {
    doc.setFontSize(11);
    doc.setFont("helvetica", "bold");
    doc.text("Exchange Rate", margin, currentVerticalPosition);

    doc.setFillColor(247, 250, 252);
    doc.roundedRect(margin, currentVerticalPosition + 5, contentWidth, 15, 2, 2, "F");

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0);
    doc.text(
      `1.00 ${safeText(transaction.currency)} = ${transaction.finalRate} ${safeText(transaction.quoteCurrency)}`,
      margin + 5,
      currentVerticalPosition + 15
    );

    currentVerticalPosition += 30; // Adjust position correctly
  }

  /*** Beneficiary Details ***/
  doc.text("Beneficiary Details", margin, currentVerticalPosition);
  doc.setFillColor(247, 250, 252);
  doc.roundedRect(margin, currentVerticalPosition + 5, contentWidth, 70, 2, 2, "F"); // Increased height directly

  doc.setFontSize(11);
  doc.setTextColor(128, 128, 128);
  doc.text("Account Holder", margin + 5, currentVerticalPosition + 15);
  doc.text("Account Number", margin + 5, currentVerticalPosition + 25);
  doc.text(isBank ? "Bank" : "Provider", margin + 5, currentVerticalPosition + 35);
  doc.text("Payment Method", margin + 5, currentVerticalPosition + 45);
  doc.text("Time", margin + 5, currentVerticalPosition + 55);
  doc.text("Reference", margin + 5, currentVerticalPosition + 65);

  doc.setFontSize(9);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(0, 0, 0);

  addText(doc, safeText(transaction.beneficiary.accountName).toUpperCase(), margin + contentWidth / 2, currentVerticalPosition + 15, maxWidth);
  addText(doc, safeText(transaction.beneficiary.accountNumber), margin + contentWidth / 2, currentVerticalPosition + 25, maxWidth);
  addText(doc, safeText(isBank ? bankName : transaction.beneficiary.bankCode), margin + contentWidth / 2, currentVerticalPosition + 35, maxWidth);
  addText(doc, safeText(transaction.beneficiary.paymentMethod).toUpperCase(), margin + contentWidth / 2, currentVerticalPosition + 45, maxWidth);
  addText(doc, safeText(formatDateTime(transaction.created)), margin + contentWidth / 2, currentVerticalPosition + 55, maxWidth);
  addText(doc, safeText(transaction.reference).toUpperCase(), margin + contentWidth / 2, currentVerticalPosition + 65, maxWidth);

  // Move position further down for footer message
  currentVerticalPosition += 80; // Directly moved down

  /*** Footer Message ***/
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.setTextColor(128, 128, 128);
  doc.text(
    "Thank you for choosing Passportmonie",
    (pageWidth - doc.getTextWidth("Thank you for choosing Passportmonie")) / 2,
    currentVerticalPosition + 20 // Pushed further down
  );


  const filename = `PassportMonie-Receipt-${transaction.reference}.pdf`;
  doc.save(filename);
}
