import {
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";

import { apiClient } from "../../api";
import {
  type GET_BANKS_API_RESPONSE,
} from "./types";

export const banksResourcePath = "payments/banks/country/NG";

interface GetBanksReturnType {
  isLoading: boolean;
  data: GET_BANKS_API_RESPONSE | null;
  isSuccess: boolean;
  error: unknown;
}

const useGetBanks = (url: string, isBank:boolean): GetBanksReturnType => {
  const hash = [url];

  const {
    isLoading,
    data = null,
    isSuccess,
    error,
  }: UseQueryResult<GET_BANKS_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
    {
      enabled: Boolean(isBank),
    }
  );

  return { isLoading, data, isSuccess, error };
};

export {
  useGetBanks,
};
