import { Link } from "@tanstack/react-router";
import { BanknotesIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";

import Spacer from "../../Spacer";
import { StatusPill } from "../StatusPill";
import { transactionStatusColors, TransactionStatus, TransactionOperation } from "../../../utils/transaction";
import { Currency, WithdrawalType } from "../../../constants";

interface TransactionItemProps {
  id: string;
  title: string;
  currency: Currency;
  amount: number;
  status: TransactionStatus;
  operation: TransactionOperation;
  withdrawalType: WithdrawalType;
  quoteAmount?: number;
  quoteCurrency?: Currency;
}

export function TransactionItem({
  id,
  title,
  currency,
  amount,
  status,
  operation,
  withdrawalType,
  quoteAmount,
  quoteCurrency,
}: TransactionItemProps) {

const isDebit = operation === TransactionOperation.WITHDRAWAL;

const isCurrencyExchange = withdrawalType === WithdrawalType.INTERNATIONAL

  return (
    <Link
      to={`/transactions/${id}`}
      key={id}
    >
      <div className="border-b pb-3 mb-3 flex w-full gap-3 justify-start">
        <div
          className={`h-10 w-10 flex rounded-full justify-center items-center font-bold ${transactionStatusColors[status]}`}
        >
          {isDebit ? <PaperAirplaneIcon className="h-3 -rotate-45" /> : <BanknotesIcon className="h-3" />}
        </div>
        <div className="grow">
          <div className="flex justify-between">
            <p className="text-xs basis-3/5 grow">{title}</p>
            <p className="font-semibold text-xs basis-2/5 grow text-right">
              {isDebit ? "-" : "+"} {amount.toLocaleString("en-US")} {currency}
            </p>
          </div>
          <Spacer />
          <div className="flex justify-between">
            <StatusPill status={status} />
            {isCurrencyExchange && (
              <p className="text-xs text-gray-500">
                {isDebit ? "-" : "+"}
                {quoteAmount?.toLocaleString("en-US")} {quoteCurrency}
              </p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
