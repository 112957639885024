import { createFileRoute, useRouter } from "@tanstack/react-router";
import { GoArrowLeft } from "react-icons/go";
import { FiDownload } from "react-icons/fi";

import Spacer from "../../../components/Spacer";
import { StatusPill } from "../../../components/transactions/StatusPill";
import { generateReceipt } from "../../../components/transactions/TransactionReceipt";
import {
  useGetTransaction,
} from "../../../services/queries/transactions";
import {
  useGetBanks,
  banksResourcePath,
} from "../../../services/queries/banks";
import useFilters from "../../../hooks/useFilters";
import { formatDateTime } from "../../../utils/transaction";
import PageTransition from "../../../components/PageTransition";
import { PaymentMethod, WithdrawalType } from "../../../constants";

export const Route = createFileRoute("/_app/transactions/$id")({
  component: TransactionPage,
});

function TransactionPage() {
  const { history } = useRouter();
  const { id } = Route.useParams<{ id: string }>();

  const { data: transactionData, isLoading } = useGetTransaction(id);
  const transaction = transactionData?.data;
  const {
    beneficiary: { bankCode, paymentMethod } = {},
    withdrawalType
  } = transactionData?.data ?? {};

  const isBank = Boolean(paymentMethod === PaymentMethod.BANK);

  const { url } = useFilters(banksResourcePath);
  const { data: banksData, isLoading: isLoadingBanks } = useGetBanks(url.href, isBank);
  const banks = banksData?.data;
  const bankName = banks?.find((bank) => bank.code === bankCode)?.name;

  const isCurrencyExchange = withdrawalType === WithdrawalType.INTERNATIONAL

  if (isLoading || isLoadingBanks) {
    return (
      <PageTransition>
        <div>Loading...</div>
      </PageTransition>
    );
  }

  if (!transaction) {
    return (
      <PageTransition>
        <div>Transaction not found</div>
      </PageTransition>
    );
  }

  return (
    <PageTransition>
      <div className="p-8 pb-24">
        <div className="relative">
          <GoArrowLeft
            size={24}
            className="absolute cursor-pointer"
            onClick={() => history.go(-1)}
          />
          <p className="font-bold text-xl text-blue-600 text-center">
            Transaction Details
          </p>
        </div>
        <Spacer size="lg" />
        <div className="flex justify-between items-center">
          <StatusPill status={transaction.status} />
          <div
            className="flex text-sm font-semibold gap-1 items-center cursor-pointer"
            onClick={() =>
              generateReceipt(
                transaction,
                bankName ?? "Bank",
              )
            }
          >
            Receipt
            <FiDownload />
          </div>
        </div>
        <Spacer size="lg" />
        <div className="border rounded-md p-4 transaction-summary-container mb-3">
          <div className="flex justify-between">
            <p className="text-sm">You sent</p>
            <p className="text-sm">Recipient got</p>
          </div>
          <div className="flex justify-between">
            <p className="text-md font-bold">
              {transaction?.amount.toLocaleString("en-US")}{" "}
              {transaction?.currency}
            </p>
            <p className="text-md font-bold">
              {!isCurrencyExchange && `${transaction?.amount?.toLocaleString("en-US")} ${transaction?.currency}`}
              {isCurrencyExchange && `${transaction?.quoteAmount?.toLocaleString("en-US")} ${transaction?.quoteCurrency}`}
            </p>
          </div>
        </div>

        {isCurrencyExchange && (
          <div className="exchange-rate-container">
            <div className="flex justify-between">
              <p className="text-sm">Exchange Rate</p>
              <p className="text-sm">
                1 {transaction?.currency} = {transaction?.finalRate} {transaction?.quoteCurrency}
              </p>
            </div>
          </div>
        )}

        <Spacer size="lg" />
        <div className="beneficiary-section">
          <p className="text-xs uppercase mb-5 ">Beneficiary Details</p>
          <div className="flex justify-between">
            <p className="text-sm">Account holder</p>
            <p className="text-xs w-[121px] sm:w-auto text-right">{transaction?.beneficiary.accountName}</p>
          </div>
          <Spacer size="md" />
          <div className="flex justify-between">
            <p className="text-sm">Account number</p>
            <p className="text-sm">{transaction?.beneficiary.accountNumber}</p>
          </div>
          <Spacer size="md" />
          <div className="flex justify-between">
            <p className="text-sm">{isBank ? `Bank` : `Provider`}</p>
            <p className="text-sm">{isBank ? bankName : bankCode}</p>
          </div>
          <Spacer size="md" />
          <div className="flex justify-between">
            <p className="text-sm">Payment Method</p>
            <p className="text-sm">{paymentMethod}</p>
          </div>
          <Spacer size="md" />
        </div>

        <div className="border-b transaction-extras" />
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Time</p>
          <p className="text-sm">
            {formatDateTime(transaction?.created)}
          </p>
        </div>
        <Spacer size="md" />
        <div className="flex justify-between">
          <p className="text-sm">Reference</p>
          <p className="text-sm">{transaction?.reference}</p>
        </div>
      </div>
    </PageTransition>
  );
} 